import React from "react";
import { Modal, Spinner } from "react-bootstrap";
import "./loader.css"

function Loader(props) {
  return (
    <Modal
          show={props.loading}
          animation={false}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Spinner animation="border" role="status" variant="light">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </Modal>
  );
}

export default Loader;
