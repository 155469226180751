import React, { Component } from "react";
import { withFirebase } from "../../services/Firebase";
import * as ROLES from "../../constants/roles";
import * as ROUTES from "../../constants/routes";
import withAuthorization from "../../components/Session/withAuthorization";
import { compose } from "recompose";
import { Container, Row, Col, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import "./index.css"

class LeadsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      users: [],
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    const usersList = [];
    this.props.firebase
      .leads().orderBy("createdAt", "desc")
      .get()
      .then((data) => {
        data.forEach((doc) => {
          const data = doc.data();

          const date = new Date(0); // The 0 there is the key, which sets the date to the epoch
          date.setUTCSeconds(data.createdAt);
          
          let a = [{day: 'numeric'}, {month: 'short'}, {year: 'numeric'}];
          let s = this.join(date, a, '-');

          usersList.push({
            id: doc.id,
            email: data.email,
            comments: data.comments,
            createdAt: s,
            fullName: data.fullName,
            language: data.language,
            official: data.official,
            pair: data.pair,
            status: data.status,
            text: "",
            words: 0,
          });
        });
        this.setState({
          users: usersList,
          loading: false,
        });
      });
  }

   join(t, a, s){
    function format(m) {
       let f = new Intl.DateTimeFormat('en', m);
       return f.format(t);
    }
    return a.map(format).join(s);
 }

  componentWillUnmount() {}

  render() {
    const { users, loading } = this.state;
    return (
      <div className="container">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header pb-0">
                  <div className="card-actions float-right"></div>
                  <h5 className="card-title mb-0">List of leads</h5>
                </div>
                <div className="card-body">
                  <Container>
                    <Loader loading={loading} />
                    <Row>
                      <Col>
                        <LeadList users={users} />
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const LeadList = ({ users }) => (
  <Table striped bordered hover>
    <thead>
      <tr>
        <th>Created</th>
        <th>Full Name</th>
        <th>Email</th>
        <th>Language</th>
        <th>Other</th>
        <th>Official</th>
        <th>Status</th>
        <th>Comments</th>
        <th>Options</th>
      </tr>
    </thead>
    <tbody>
      {users.map((user) => (
        <tr key={user.id}>
          <td>{user.createdAt}</td>
          <td>{user.fullName}</td>
          <td>{user.email}</td>
          <td>{user.language}</td>
          <td>{user.pair}</td>
          <td>{user.official ? "YES" : "NO"}</td>
          <td>{user.status}</td>
          <td>{user.comments}</td>
          <td>
            <Link to={`${ROUTES.LEAD_DETAIL_BASE}/${user.id}`}>Details</Link>
          </td>
        </tr>
      ))}
    </tbody>
  </Table>
);

const condition = (authUser) => authUser && authUser.roles.includes(ROLES.ADMIN);

export default compose(withAuthorization(condition), withFirebase)(LeadsPage);
