import React, { Component } from "react";
import { Link } from "react-router-dom";

import { withFirebase } from "../../services/Firebase";
import * as ROUTES from "../../constants/routes";
import { Container, Row, Col, Form, Button } from "react-bootstrap";

const PasswordForgetPage = () => (
  <Container>
    <Row>
      <Col>
        <h1>Password Forget?</h1>
        <PasswordForgetForm />
      </Col>
    </Row>
  </Container>
);

const INITIAL_STATE = {
  email: "",
  error: null,
};

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const { email } = this.state;
    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch((error) => {
        this.setState({ error });
      });
    event.preventDefault();
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, error } = this.state;

    const isInvalid = email === "";

    return (
      <Form onSubmit={this.onSubmit}>
        <Form.Group>
          <Form.Label>Email:</Form.Label>
          <Form.Control
            type="email"
            id="email"
            name="email"
            value={this.state.email}
            onChange={this.onChange}
            placeholder="Email Address"
          />
        </Form.Group>
        <Button disabled={isInvalid} type="submit">
          Reset My Password
        </Button>

        {error && <p>{error.message}</p>}
      </Form>
    );
  }
}

const PasswordForgetLink = () => (
  <p>
    <Link to={ROUTES.PASSWORD_FORGET}>Forgot password?</Link>
  </p>
);

export default PasswordForgetPage;

const PasswordForgetForm = withFirebase(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink };
