import React from "react";
import { withAuthorization } from "../../components/Session";
import { Container, Row, Col } from "react-bootstrap";

const HomePage = () => (
  <div className="container">
    <div className="container-fluid p-0">
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header pb-0">
              <div className="card-actions float-right"></div>
              <h5 className="card-title mb-0">Garnett Translapp</h5>
            </div>
            <div className="card-body">
              <Container>
                <Row>
                  <Col>
                    <strong>Version 0.0.2</strong>
                    <ul>
                      <li>Autoresponse email</li>
                      <li>New lead confirmation email</li>
                      <li>Cotizador form validation</li>
                      <li>Role in Users List</li>
                      <li>
                        Status icon in Users List and change status action when
                        clicking the icon{" "}
                      </li>
                    </ul>
                    <strong>Version 0.0.3</strong>
                    <ul>
                      <li>Fix loading OCR in Lead Details</li>
                      <li>GCP ContentProcessor v.0.0.1</li>
                      <li>Using ContentProcessor v.0.0.1 on Lead Details</li>
                      <li>My profile page</li>
                    </ul>
                    <strong>Version 0.0.4</strong>
                    <ul>
                      <li>Adding Smart Price to GCP API</li>
                      <li>SmartPrice page</li>
                      <li>Secure API with basic authentication</li>
                      <li>Adding SMARTPRICED as status</li>
                      <li>Adding "total pagado " and "Total traductor" to lead information in Lead Details page</li>
                      <li>Fixing autoresponder email and adding price</li>
                      <li>Changing admin email to fabioandresrodriguez@gmail.com to avoid spam on my email :-)</li>
                      <li>emailJS QUOTA REMAINING: 103 EMAILS (26-03-2021)</li>
                    </ul>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(HomePage);
