import { Component } from "react";
import { compose } from "recompose";
import withAuthorization from "../../../../components/Session/withAuthorization";
import { withFirebase } from "../../../../services/Firebase";
import * as ROLES from "../../../../constants/roles";
import { Container, Row, Col, Form, Button } from "react-bootstrap";

const INITIAL_STATE = {
  username: "",
  email: "",
  passwordOne: "",
  passwordTwo: "",
  isAdmin: false,
  error: null,
};

class CreateUsersPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
    this.state = { ...INITIAL_STATE };
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onSubmit = (event) => {
    event.preventDefault();
    const { username, email, passwordOne } = this.state;
    const roles = {};

    roles[ROLES.USER] = ROLES.USER;

    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then((newUser) => {
        return this.props.firebase.user(newUser.user.uid).set({
          username,
          email,
          "roles":[ROLES.USER],
          "status": "INACTIVE",
          
        });
      })
      .then((newUser) => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch((error) => {
        this.setState({ error });
      });
  };

  render() {
    const {
      username,
      email,
      passwordOne,
      passwordTwo,
      error,
      isInvalid,
    } = this.state;
    return (
      <div className="container">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  <Container>
                    <Row>
                      <Col>
                        <h1>Add user</h1>
                        <Form onSubmit={this.onSubmit}>
                          <Form.Group>
                            <Form.Label>Full name</Form.Label>
                            <Form.Control
                              name="username"
                              value={username}
                              onChange={this.onChange}
                              type="text"
                              placeholder="Full Name"
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                              name="email"
                              value={email}
                              onChange={this.onChange}
                              type="text"
                              placeholder="Email Address"
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                              name="passwordOne"
                              value={passwordOne}
                              onChange={this.onChange}
                              type="password"
                              placeholder="Password"
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Confirm password</Form.Label>
                            <Form.Control
                              name="passwordTwo"
                              value={passwordTwo}
                              onChange={this.onChange}
                              type="password"
                              placeholder="Confirm Password"
                            />
                          </Form.Group>

                          <Button disabled={isInvalid} type="submit">
                            Sign Up
                          </Button>

                          {error && <p>{error.message}</p>}
                        </Form>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const condition = (authUser) => authUser && authUser.roles.includes(ROLES.ADMIN);

export default compose(
  withAuthorization(condition),
  withFirebase
)(CreateUsersPage);
