import React, { Component } from "react";
import { withFirebase } from "../../../services/Firebase";
import * as ROLES from "../../../constants/roles";
import withAuthorization from "../../../components/Session/withAuthorization";
import { compose } from "recompose";
import { Container, Row, Col, Table } from "react-bootstrap";
import "./index.css";
import Loader from "../../../components/Loader/Loader";
import LinkButton from "../../../components/LinkButton/LinkButton";

class UsersPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      users: [],
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    const usersList = [];
    this.props.firebase
      .users()
      .get()
      .then((data) => {
        data.forEach((doc) => {
          usersList.push({
            id: doc.id,
            email: doc.data().email,
            uid: "pending",
            status: doc.data().status,
            role: doc.data().roles[0],
          });
        });
        this.setState({
          users: usersList,
          loading: false,
        });
      });
  }

  componentWillUnmount() {}

  handleStatusChange = (user) => {
    const newStatus = user.status === "active" ? "inactive" : "active";

    const data = {
      status: newStatus,
    };

    const newUsers = this.state.users.map((item) => {
      if (item.id === user.id) {
        const updatedItem = {
          ...item,
          status: newStatus,
        };
        return updatedItem;
      }
      return item;
    });

    this.setState({
      users: newUsers,
      loading: false,
    });

    const userRef = this.props.firebase.user(user.id);
    userRef
      .update(data)
      .then((docRef) => {
        console.log("updated");
      })
      .catch((error) => {
        console.log("Error creating record:", error);
      });
  };

  render() {
    const { users, loading } = this.state;
    return (
      <div className="container">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header">
                  <div className="card-actions float-right"></div>
                  <h5 className="card-title mb-0">List of Users</h5>
                  <p>Click on status to active or inactive user</p>
                </div>
                <div className="card-body">
                  <Loader loading={loading} />
                  {false && (<Container>
                    <Row>
                      <Col xs={10}></Col>
                      <Col>
                        <LinkButton to="/admin/users/create">
                          Create User
                        </LinkButton>
                      </Col>
                    </Row>
                  </Container>)}
                  <Container>
                    <Row>
                      <Col>
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th>Email</th>
                              <th>Role</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {users.map((user) => (
                              <tr key={user.id}>
                                <td>{user.email}</td>
                                <td>{user.role}</td>
                                <td>
                                  <button
                                    type="button"
                                    className="link-button"
                                    onClick={() =>
                                      this.handleStatusChange(user)
                                    }
                                  >
                                    {user.status === "active" ? (
                                      <span className="badge bg-success">
                                        Active
                                      </span>
                                    ) : (
                                      <span className="badge bg-warning">
                                        Inactive
                                      </span>
                                    )}
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const condition = (authUser) => authUser && authUser.roles.includes(ROLES.ADMIN);

export default compose(withAuthorization(condition), withFirebase)(UsersPage);
