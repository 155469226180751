import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Navigation from "../Navigation";
import LandingPage from "../../pages/Landing";
import SignUpPage from "../../pages/SignUp";
import SignInPage from "../../pages/SignIn/sign_in_page";
import PasswordForgetPage from "../../pages/PasswordForget";
import HomePage from "../../pages/HomePage";
import UserPage from "../../pages/Profile";
import UsersPage from "../../pages/Admin/Users";
import LeadsPage from "../../pages/LeadsPage";

import * as ROUTES from "../../constants/routes";
import { withAuthentication } from "../Session";
import leadDetails from "../../pages/LeadsPage/lead-details/lead-details";
import SmartPricePage from "../../pages/SmartPrice";
import createUserPage from "../../pages/Admin/Users/create_user/create_user";


const App = () => (
  <Router>
    <div>
      <Navigation />
      <hr />
      <Route exact path={ROUTES.LANDING} component={LandingPage} />
      <Route exact path={ROUTES.SMARTPRICE} component={SmartPricePage} />
      <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
      <Route path={ROUTES.SIGN_IN} component={SignInPage} />
      <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
      <Route path={ROUTES.HOME} component={HomePage} />
      <Route path={ROUTES.USER} component={UserPage} />
      
      <Route exact path={ROUTES.LIST_LEADS} component={LeadsPage} />
      <Route exact path={ROUTES.LEAD_DETAIL} component={leadDetails} />
      <Route exact path={ROUTES.ADMIN_USERS} component={UsersPage} />
      <Route path={ROUTES.ADMIN_USERS_CREATE} component={createUserPage} />
    </div>
  </Router>
);

export default withAuthentication(App);
