import React from "react";

import SignOutButton from "../SignOut";
import * as ROUTES from "../../constants/routes";
import * as ROLES from "../../constants/roles";

import { AuthUserContext } from "../Session";
import { Nav, Navbar } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const Navigation = ({ authUser }) => (
  <div>
    <AuthUserContext.Consumer>
      {(authUser) =>
        authUser ? (
          <NavigationAuth authUser={authUser} />
        ) : (
          <NavigationNonAuth />
        )
      }
    </AuthUserContext.Consumer>
  </div>
);

const NavigationAuth = ({ authUser }) => (
  <Navbar>
    <Nav.Item>
      <LinkContainer to={ROUTES.LANDING}>
        <Nav.Link>Cotizador</Nav.Link>
      </LinkContainer>
    </Nav.Item>
    <Nav.Item>
      <LinkContainer to={ROUTES.SMARTPRICE}>
        <Nav.Link>SmartPrice</Nav.Link>
      </LinkContainer>
    </Nav.Item>
    <Nav.Item>
      <LinkContainer to={ROUTES.HOME}>
        <Nav.Link>Home</Nav.Link>
      </LinkContainer>
    </Nav.Item>
    <Nav.Item>
      <LinkContainer to={ROUTES.USER}>
        <Nav.Link>My profile</Nav.Link>
      </LinkContainer>
    </Nav.Item>
    {authUser.roles.includes(ROLES.ADMIN) && (
      <>
        <Nav.Item>
          <LinkContainer to={ROUTES.ADMIN_USERS}>
            <Nav.Link>Users</Nav.Link>
          </LinkContainer>
        </Nav.Item>
        <Nav.Item>
          <LinkContainer to={ROUTES.LIST_LEADS}>
            <Nav.Link>List Leads</Nav.Link>
          </LinkContainer>
        </Nav.Item>
      </>
    )}
    <Nav.Item>
      <SignOutButton />
    </Nav.Item>
  </Navbar>
);

const NavigationNonAuth = () => (
  <Navbar>
    <Nav.Item>
      <LinkContainer to={ROUTES.LANDING}>
        <Nav.Link>Cotizador</Nav.Link>
      </LinkContainer>
    </Nav.Item>
    <Nav.Item>
      <LinkContainer to={ROUTES.SMARTPRICE}>
        <Nav.Link>SmartPrice</Nav.Link>
      </LinkContainer>
    </Nav.Item>
    <Nav.Item>
      <LinkContainer to={ROUTES.SIGN_IN}>
        <Nav.Link>Sign In</Nav.Link>
      </LinkContainer>
    </Nav.Item>
  </Navbar>
);

export default Navigation;
