export const LANDING = "/";
export const SMARTPRICE = "/smartprice";
export const SIGN_UP = "/signup";
export const SIGN_IN = "/signin";
export const HOME = "/home";
export const USER = "/user";
export const ADMIN_USERS = "/admin/users";
export const ADMIN_USERS_CREATE = "/admin/users/create";
export const PASSWORD_FORGET = "/pw-forget";
export const LIST_LEADS = "/leads/list";
export const LEAD_DETAIL_BASE = "/leads/detail";
export const LEAD_DETAIL = LEAD_DETAIL_BASE + "/:leadId";
