import React from "react";
import { PasswordForgetForm } from "../PasswordForget";
import PasswordChangeForm from "../../components/PasswordChange";
import { withAuthorization, AuthUserContext } from "../../components/Session";
import { Container, Row, Col } from "react-bootstrap";
import "./index.css";
import avatar from "../../avatar.png";
import EditableText from "../../components/EditableText/EditableText";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

class UserPage extends React.Component {
  static contextType = AuthUserContext;

  constructor(props, context) {
    super(props);
    const INITIAL_STATE = {
      fullName: context.fullName,
      address: context.address,
      mobile: context.mobile,
      mobileSec: condition.mobileSec,
      website: context.mobile,
      description: context.description,
      uid: context.uid,
      times: context.times,
      show: false,
    };
    let eventGuid = 0;
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {

  }

  createEventId() {
    return String(this.eventGuid++);
  }

  handleDateSelect = (selectInfo) => {
    let calendarApi = selectInfo.view.calendar;
    calendarApi.unselect(); // clear date selection
    const event = {
      title: "Available",
      start: selectInfo.startStr,
      end: selectInfo.endStr,
      allDay: selectInfo.allDay,
    };
    calendarApi.addEvent(event);
  };

  handleEventClick = (clickInfo) => {
    if (window.confirm("Do you want to delete it?")) {
      clickInfo.event.remove();
    }
  };

  handleEvents = (rawEvents) => {
    const events = [];
    rawEvents.forEach((e) =>
      events.push({
        title: "Available",
        start: e.startStr,
        end: e.endStr,
        allDay: e.allDay,
      })
    );
    this.setState({
      currentEvents: events,
    });

    //this.saveAvailability();
  };

  saveAvailability = () => {
    const userRef = this.props.firebase.user(this.state.uid);
    userRef.update({ times: this.state.currentEvents }).catch((error) => {
      console.log("Error creating record:", error);
    });
  };

  handleChange = (data) => {
    let temp = {};

    if (data.fieldName === "fullName") {
      temp = { fullName: data.fieldValue };
      this.setState(temp);
    }

    if (data.fieldName === "mobile") {
      temp = { mobile: data.fieldValue };
    }

    if (data.fieldName === "mobileSec") {
      temp = { mobileSec: data.fieldValue };
    }

    if (data.fieldName === "address") {
      temp = { address: data.fieldValue };
    }

    if (data.fieldName === "description") {
      temp = { description: data.fieldValue };
    }

    const userRef = this.props.firebase.user(this.state.uid);
    userRef.update(temp).catch((error) => {
      console.log("Error creating record:", error);
    });
  };

  handleEvents = (rawEvents) => {
    const events = [];
    rawEvents.forEach((e) =>
      events.push({
        title: "Available",
        start: e.startStr,
        end: e.endStr,
        allDay: e.allDay,
      })
    );
    this.setState({
      currentEvents: events,
    });
  };

  handleClose = () => {
    this.setState({
      show: false,
    });
  };

  handleShow = () => {
    this.setState({
      show: true,
    });
  };

  render() {
    const {
      fullName,
      address,
      mobile,
      mobileSec,
      description,
      times,
    } = this.state;
    return (
      <AuthUserContext.Consumer>
        {(authUser) => (
          <>
            <Container>
              <Row>
                <Col>
                  <div className="container">
                    <div className="main-body">
                      <div className="row gutters-sm">
                        <div className="col-md-4 mb-3">
                          <div className="card">
                            <div className="card-body">
                              <div className="d-flex flex-column align-items-center text-center">
                                <img
                                  src={avatar}
                                  alt="Admin"
                                  className="rounded-circle"
                                  width="150"
                                />
                                <div className="mt-3">
                                  <h4>{fullName}</h4>
                                  <p className="text-secondary mb-1">
                                    <EditableText
                                      name="description"
                                      value={description}
                                      editClassName="form-control"
                                      onChangeText={this.handleChange}
                                    />
                                  </p>
                                  <p className="text-muted font-size-sm">
                                    {address}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="card mt-3"></div>
                        </div>
                        <div className="col-md-8">
                          <div className="card mb-3">
                            <div className="card-body">
                              <div className="row">
                                <div className="col-sm-3">
                                  <h6 className="mb-0">Full Name</h6>
                                </div>
                                <div className="col-sm-9 text-secondary">
                                  <EditableText
                                    name="fullName"
                                    value={fullName}
                                    editClassName="form-control"
                                    onChangeText={this.handleChange}
                                  />
                                </div>
                              </div>
                              <hr />
                              <div className="row">
                                <div className="col-sm-3">
                                  <h6 className="mb-0">Email</h6>
                                </div>
                                <div className="col-sm-9 text-secondary">
                                  {authUser.email}
                                </div>
                              </div>
                              <hr />
                              <div className="row">
                                <div className="col-sm-3">
                                  <h6 className="mb-0">Mobile #1</h6>
                                </div>
                                <div className="col-sm-9 text-secondary">
                                  <EditableText
                                    name="mobile"
                                    value={mobile}
                                    editClassName="form-control"
                                    onChangeText={this.handleChange}
                                  />
                                </div>
                              </div>
                              <hr />
                              <div className="row">
                                <div className="col-sm-3">
                                  <h6 className="mb-0">Mobile #2</h6>
                                </div>
                                <div className="col-sm-9 text-secondary">
                                  <EditableText
                                    name="mobileSec"
                                    value={mobileSec}
                                    editClassName="form-control"
                                    onChangeText={this.handleChange}
                                  />
                                </div>
                              </div>
                              <hr />
                              <div className="row">
                                <div className="col-sm-3">
                                  <h6 className="mb-0">Address</h6>
                                </div>
                                <div className="col-sm-9 text-secondary">
                                  <EditableText
                                    name="address"
                                    value={address}
                                    editClassName="form-control"
                                    onChangeText={this.handleChange}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="card mb-3">
                            <div className="card-body">
                              <FullCalendar
                                plugins={[
                                  dayGridPlugin,
                                  timeGridPlugin,
                                  interactionPlugin,
                                ]}
                                headerToolbar={{
                                  left: "prev,next",
                                  center: "title",
                                  right: "timeGridWeek",
                                }}
                                initialEvents={times}
                                initialView="timeGridWeek"
                                editable={true}
                                selectable={true}
                                selectMirror={true}
                                dayMaxEvents={true}
                                weekends={this.state.weekendsVisible}
                                select={this.handleDateSelect}
                                //eventContent={renderEventContent} // custom render function
                                eventClick={this.handleEventClick}
                                eventAdd={this.saveAvailability}
                                eventsSet={this.handleEvents}
                                eventChange={this.saveAvailability}
                                eventRemove={this.saveAvailability}
                                //eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
                                /* you can update a remote database when these fire:
                                
                          
                          eventRemove={function(){}}
                          */
                              />
                            </div>
                          </div>
                          <div className="card mb-3">
                            <div className="card-body">
                              <PasswordForgetForm />
                            </div>
                          </div>
                          <div className="card mb-3">
                            <div className="card-body">
                              <PasswordChangeForm />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(UserPage);
