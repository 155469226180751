import React, { Component } from "react";
import { withRouter } from "react-router";
import compose from "recompose/compose";
import * as ROUTES from "../../constants/routes";
import { withFirebase } from "../../services/Firebase";
import { SignUpLink } from "../SignUp";
import { PasswordForgetLink } from "../PasswordForget";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import "./sign_in_page.css";

const SignInPage = () => (
  <div className="container">
    <div className="container-fluid p-0">
      <div className="row">
        <div className="col-xl-3"></div>
        <div className="col-xl-6">
          <div className="card">
            <div className="card-body">
              <Container>
                <Row>
                  <Col>
                    <h1>Garnett Translapp</h1>
                    <SignInForm />
                    <PasswordForgetLink />
                    <SignUpLink />
                    
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
        <div></div>
      </div>
      <div className="row">
          <div className="col-xl-3"></div>
          <Col xs={6}>
            <p className="version">Version 0.0.4</p>
          </Col>
          <div></div>
        </div>
    </div>
  </div>
);

const INITIAL_STATE = {
  email: "",
  password: "",
  error: null,
};

class SignInFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const { email, password } = this.state;
    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then((result) => {
        console.log(result);
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.HOME);
      })
      .catch((error) => {
        this.setState({ error });
      });
    event.preventDefault();
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, password, error } = this.state;
    const isInvalid = password === "" || email === "";

    return (
      <Form onSubmit={this.onSubmit}>
        <Form.Group>
          <Form.Label>Email:</Form.Label>
          <Form.Control
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={this.onChange}
            placeholder="Email Address"
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Password</Form.Label>
          <Form.Control
            name="password"
            value={password}
            onChange={this.onChange}
            type="password"
            placeholder="Password"
          />
        </Form.Group>
        <Button variant="primary" type="submit" disabled={isInvalid}>
          Sign In
        </Button>
        {error && <p>{error.message}</p>}
      </Form>
      
    );
  }
}

const SignInForm = compose(withFirebase, withRouter)(SignInFormBase);

export default SignInPage;
export { SignInForm };
