import React, { Component } from "react";
import { Container, Row, Col, Alert, Form, Button } from "react-bootstrap";
import { withFirebase } from "../../services/Firebase";
import { compose } from "recompose";
import uuid from "react-uuid";
import "./index.css";
import emailjs from "emailjs-com";

const INITIAL_STATE = {
  language: "ES-EN",
  pair: "",
  selectedFile: "",
  fullName: "",
  email: "",
  official: "Y",
  comments: "",
  errors: [],
};

class LandingPageBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.onFileSubmit = this.onFileSubmit.bind(this);
  }

  hasError(key) {
    return this.state.errors.indexOf(key) !== -1;
  }

  handleInputChange(event) {
    var key = event.target.name;
    var value = event.target.value;
    var obj = {};
    obj[key] = value;
    this.setState(obj);
  }

  onChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  onChangeCheckbox = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  _handleReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    this.setState({
      base64TextString: btoa(binaryString),
    });
  };

  onFileChange = (e) => {
    let file = e.target.files[0];

    this.setState({
      selectedFile: file,
    });
    /*
    if (file) {
      const reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
    */
  };

  sendEmails = (templateParams) => {
    if (templateParams["language"] === "ES-EN") {
      templateParams["language"] = "Español - Ingles";
    } else if (templateParams["language"] === "EN-ES") {
      templateParams["language"] = "Español - Ingles";
    } else {
      templateParams["language"] = templateParams["pair"];
    }

    if (templateParams["official"] === "Y") {
      templateParams["official"] = "SI";
    } else {
      templateParams["official"] = "NO";
    }

    this.sendAdminEmail(templateParams);
    this.sendCustomerEmail(templateParams);
  };

  sendAdminEmail = (templateParams) => {
    templateParams["from_name"] = "Dev";
    templateParams["admin_email"] = process.env.REACT_APP_ADMIN_EMAIL;
    console.log(templateParams);
    emailjs
      .send(
        "service_gh211yk",
        "lead_0wn99w8",
        templateParams,
        "user_4zImBKXZKLqt25LWJPA8u"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  sendCustomerEmail = (templateParams) => {
    templateParams["from_name"] = "Dev";
    emailjs
      .send(
        "service_gh211yk",
        "autoresponder_wjr0ch3",
        templateParams,
        "user_4zImBKXZKLqt25LWJPA8u"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    var errors = [];

    const SUPPORTED_FORMATS = [
      "image/jpg",
      "image/jpeg",
      "image/png",
      "application/pdf",
    ];

    //firstname
    if (this.state.fullName === "") {
      errors.push("fullName");
    }

    //email
    const expression = /\S+@\S+/;
    var validEmail = expression.test(String(this.state.email).toLowerCase());

    if (!validEmail) {
      errors.push("email");
    }

    //File
    if (
      this.state.selectedFile === "" ||
      !SUPPORTED_FORMATS.includes(this.state.selectedFile.type)
    ) {
      errors.push("selectedFile");
    }

    await this.setState({
      errors: errors,
    });
  };

  onFileSubmit = async (e) => {
    e.preventDefault();

    await this.handleSubmit(e);

    if (this.state.errors.length > 0) {
      return false;
    }

    const formData = new FormData();
    const userId = uuid();
    formData.append("file", this.state.selectedFile);
    formData.append("userId", userId);

    /**
     * Call API to add to storage
     */
    await fetch("https://concrete-list-303103.uc.r.appspot.com/storage", {
      method: "POST",
      body: formData,
    })
      .then((result) => console.log("Document uploaded to cloud"))
      .catch((error) => console.log(error));

    const userRef = this.props.firebase.leads();
    const now = new Date();
    const secondsSinceEpoch = Math.round(now.getTime() / 1000);

    const data = {
      createdAt: secondsSinceEpoch,
      pair: this.state.pair,
      fullName: this.state.fullName,
      status: "CREATED",
      language: this.state.language,
      email: this.state.email,
      comments: this.state.comments,
      official: this.state.official,
      fileName: this.state.selectedFile.name,
      fileType: this.state.selectedFile.type,
      text: "",
      words: 0,
    };

    /**
     * Save info in database
     */

    userRef
      .doc(userId)
      .set(data)
      .then((docRef) => {
        this.setState({
          docRef: userId,
        });
        this.sendEmails(data);
      })
      .catch((error) => {
        console.log("Error creating record:", error);
      });

    this.setState(INITIAL_STATE);
  };

  render() {
    const { pair, fullName, email, comments } = this.state;

    return (
      <div className="container">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  <Container>
                    {this.state.docRef && (
                      <Alert variant="primary">
                        Gracias por su interés en usar nuestros servicios de
                        traducción. Su archivo ya fue cargado al sistema y
                        pronto lo contactaremos para darle mas información
                      </Alert>
                    )}

                    <Row>
                      <Col>
                        <h1>¿Qué deseas traducir?</h1>
                        <p>
                          Completa el siguiente formulario y recibe en tu correo
                          electrónico una cotización de traducción oficial o no
                          oficial, con precio y tiempo de entrega.
                        </p>
                        <Form noValidate onSubmit={this.onFileSubmit}>
                          <Form.Group>
                            <Form.Label>Idioma de la traducción</Form.Label>
                            <Form.Control
                              as="select"
                              type="text"
                              name="language"
                              onChange={this.onChange}
                              value={this.state.language}
                            >
                              <option value="ES-EN">Español a Inglés</option>
                              <option value="EN-ES">Inglés a Español</option>
                              <option value="OTRA">
                                Otra combinación de idiomas
                              </option>
                            </Form.Control>
                          </Form.Group>

                          <Form.Group
                            className={
                              this.state.language === "OTRA" ? "" : "hidden"
                            }
                          >
                            <Form.Label>Combinación de idiomas</Form.Label>
                            <Form.Control
                              type="text"
                              id="pair"
                              name="pair"
                              onChange={this.onChange}
                              value={pair}
                            />
                          </Form.Group>

                          <Form.Group>
                            <Form.File
                              id="file"
                              label="Carga archivo para cotizar (png, jpeg, jpg, pdf máximo de 5 páginas)"
                              onChange={(e) => this.onFileChange(e)}
                              name="file"
                              accept="image/png, image/jpeg, application/pdf "
                              className={
                                this.hasError("selectedFile")
                                  ? "is-invalid"
                                  : ""
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              Campo requerido o formato invalido (png, jpeg,
                              jpg, pdf)
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group>
                            <Form.Label>
                              ¿Requieres traducción oficial?
                            </Form.Label>
                            <Form.Control
                              as="select"
                              type="text"
                              name="official"
                              onChange={this.onChange}
                              value={this.state.official}
                            >
                              <option value="Y">Si</option>
                              <option value="N">No</option>
                            </Form.Control>
                          </Form.Group>

                          <Form.Group>
                            <Form.Label>Nombre completo</Form.Label>
                            <Form.Control
                              type="text"
                              id="fullName"
                              name="fullName"
                              value={fullName}
                              onChange={this.onChange}
                              className={
                                this.hasError("fullName") ? "is-invalid" : ""
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              Campo requerido
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group>
                            <Form.Label>Correo electrónico:</Form.Label>
                            <Form.Control
                              type="email"
                              id="email"
                              name="email"
                              value={email}
                              onChange={this.onChange}
                              className={
                                this.hasError("email") ? "is-invalid" : ""
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              Campo requerido
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group>
                            <Form.Label>
                              Comentarios o preguntas adicionales (Opcional)
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              id="comments"
                              name="comments"
                              value={comments}
                              onChange={this.onChange}
                            />
                          </Form.Group>
                          <Button variant="primary" type="submit">
                            Cotizar
                          </Button>
                        </Form>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const LandingPage = compose(withFirebase)(LandingPageBase);

export default LandingPage;
