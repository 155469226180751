import React, { Component } from "react";
import { withFirebase } from "../../../services/Firebase";
import * as ROLES from "../../../constants/roles";

import withAuthorization from "../../../components/Session/withAuthorization";
import { compose } from "recompose";
import { Container, Row, Col } from "react-bootstrap";
import "./lead-details.css";
import Loader from "../../../components/Loader/Loader";

class LeadDetailPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      id: "",
      email: "data.email",
      comments: "data.comments",
      createdAt: "d",
      fullName: "data.fullName",
      language: "data.language",
      official: "data.official",
      pair: "data.pair",
      status: "data.status",
      text: "",
      words: 0,
      listOfNumbers: [],
      listOfSymbols: [],
      partners: [],
      price: 0,
      serviceFee: 0,
    };
  }

  runOCR = async (event) => {
    event.preventDefault();
    this.setState({ loading: true });

    const visionBody = JSON.stringify({
      userId: this.state.id,
      fileName: this.state.fileName,
      type: this.state.fileType,
    });

    await fetch("https://concrete-list-303103.uc.r.appspot.com/vision", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: visionBody,
    })
      .then((response) => response.json())
      .then((json) => {
        this.setState({
          text: json.text,
          count: json.words,
        });
        this.setState({ loading: false });
        return json;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  componentDidMount() {
    this.setState({ loading: true });

    this.props.firebase
      .lead(this.props.match.params.leadId)
      .get()
      .then((doc) => {
        const data = doc.data();
        const d = new Date(0); // The 0 there is the key, which sets the date to the epoch
        d.setUTCSeconds(data.createdAt);

        if (doc.exists) {
          this.setState({
            id: doc.id,
            fileName: data.fileName,
            fileType: data.fileType,
            email: data.email,
            comments: data.comments,
            createdAt: d,
            fullName: data.fullName,
            language: data.language,
            official: data.official,
            pair: data.pair,
            status: data.status,
            text: data.text,
            words: data.words,
            numberOfNumbers: data.numbers,
            numberOfSymbols: data.symbols,
            listOfNumbers: data.listOfNumbers ? data.listOfNumbers : [],
            listOfSymbols: data.listOfSymbols ? data.listOfSymbols : [],
            price: data.price,
            serviceFee: data.serviceFee,
          });
        }

        const partners = [];
        this.props.firebase
          .users()
          .where("roles", "array-contains", "MORTAL")
          .get()
          .then((data) => {
            data.forEach((doc) => {
              partners.push({
                id: doc.id,
                email: doc.data().email,
                status: doc.data().status,
                role: doc.data().roles[0],
              });
            });
            this.setState({
              partners: partners,
              loading: false,
            });
          });
      });
  }

  componentWillUnmount() {}

  render() {
    const {
      loading,
      text,
      comments,
      email,
      fullName,
      language,
      words,
      numberOfNumbers,
      numberOfSymbols,
      listOfNumbers,
      listOfSymbols,
      status,
      price,
      serviceFee
    } = this.state;
    return (
      <div>
        <div className="container">
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-xl-8">
                <div className="card">
                  <div className="card-header pb-0">
                    <h5 className="card-title mb-0">
                      Información del documento
                    </h5>
                  </div>
                  <div className="card-body">
                    <Container>
                      <Loader loading={loading} />
                      <Container>
                        <Row>
                          <Col>
                            <b>
                              Comentarios o preguntas adicionales (Opcional)
                            </b>
                            <p>{comments}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <b>Texto de OCR: </b>
                            <a
                              onClick={this.runOCR}
                              href="/"
                              className={text ? "hidden" : ""}
                            >
                              Get text
                            </a>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <p>{text}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <b>Número de palabras: </b>
                            <p>{words}</p>
                          </Col>
                          <Col>
                            <b>
                              Número de palabras (Sin números, sin símbolos):{" "}
                            </b>
                            <p>{words - numberOfNumbers - numberOfSymbols}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <b>Número de palabras con números: </b>
                            <p>{numberOfNumbers}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <b>Números: </b>
                            <ul>
                              {listOfNumbers.map((number, i) => (
                                <li key={i}>{number.toString()}</li>
                              ))}
                            </ul>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <b>Número de palabras con simbolos: </b>
                            <p>{numberOfSymbols}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <b>Simbolos: </b>
                            <ul>
                              {listOfSymbols.map((symbol, i) => (
                                <li key={i}>{symbol.toString()}</li>
                              ))}
                            </ul>
                          </Col>
                        </Row>
                      </Container>
                    </Container>
                  </div>
                </div>
              </div>

              <div className="col-xl-4">
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title mb-0">Lead Information</h5>
                  </div>
                  <div className="card-body">
                    <table className="table table-sm mt-2 mb-4">
                      <tbody>
                        <tr>
                          <th>Name</th>
                          <td>{fullName}</td>
                        </tr>
                        <tr>
                          <th>Idioma de la traducción</th>
                          <td>{language}</td>
                        </tr>
                        <tr>
                          <th>¿Requieres traducción oficial?</th>
                          <td>{this.state.official ? "Si" : "No"}</td>
                        </tr>
                        <tr>
                          <th>Email</th>
                          <td>{email}</td>
                        </tr>
                        {language === "EN-ES" || language === "ES-EN" ? (
                          ""
                        ) : (
                          <tr>
                            <th>Combinación de idiomas:</th>
                            <td>{this.state.pair}</td>
                          </tr>
                        )}
                        <tr>
                          <th>Comentarios</th>
                          <td>{comments}</td>
                        </tr>
                        <tr>
                          <th>Status</th>
                          <td>
                            {status === "CREATED" ? (
                              <span className="badge bg-warning">Created</span>
                            ) : status === "OCR" ? (
                              <span className="badge bg-success">OCR</span>
                            ) : status === "SMARTPRICED" ? (
                              <span className="badge bg-success">
                                Smarpriced
                              </span>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                        {status === "SMARTPRICED" ? (
                          <tr>
                            <th>Total pagado (smartprice):</th>
                            <td>$ {new Intl.NumberFormat().format(price)}</td>
                          </tr>
                        ) : (
                          ""
                        )}

                        {status === "SMARTPRICED" ? (
                          <tr>
                            <th>Total traductor:</th>
                            <td>
                              $ {new Intl.NumberFormat().format(serviceFee)}
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}

                        {false ? (
                          <tr>
                            <th>Asignar a:</th>
                            <td>
                              <select></select>
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                      </tbody>
                    </table>

                    {false ? (
                      <div>
                        <strong>Activity </strong>

                        <ul className="timeline mt-2 mb-0">
                          <li className="timeline-item">
                            <strong>Created</strong>
                            <span className="float-right text-muted text-sm">
                              30m ago
                            </span>
                          </li>
                          <li className="timeline-item">
                            <strong>OCR</strong>
                            <span className="float-right text-muted text-sm">
                              2h ago
                            </span>
                          </li>
                        </ul>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const condition = (authUser) => authUser && authUser.roles.includes(ROLES.ADMIN);

const SelectPartners = (partners) => <select></select>;

export default compose(
  withAuthorization(condition),
  withFirebase
)(LeadDetailPage);

export { SelectPartners };
