import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_MESSAGING_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

class Firebase {
  constructor() {
    app.initializeApp(config);
    this.auth = app.auth();
    this.firestore = app.firestore();
  }

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password);

  user = (uid) => this.firestore.doc("users/" + uid);
  users = () => this.firestore.collection("users");
  times = (uid) => this.firestore.collection("users").doc(uid).collection("times");

  leads = () => this.firestore.collection("leads");
  lead = (uid) => this.firestore.doc("leads/" + uid);

  settings = () => this.firestore.collection("settings");

  // *** Merge Auth and DB User API *** //
  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        this.user(authUser.uid).onSnapshot((snapshot) => {
          const dbUser = snapshot.data();

          if (!dbUser.roles) {
            dbUser.roles = {};
          }

          authUser = {
            uid: authUser.uid,
            email: authUser.email,
            ...snapshot.data(),
          };

          next(authUser);
        });
      } else {
        fallback();
      }
    });
}

export default Firebase;
